/* eslint-disable max-len */
const CHARACTERS_LENGTH_EXCEED_MESSAGE =
  // eslint-disable-next-line quotes
  'The characters limit is 100';

const required = value => (!value ? 'This field is required' : undefined);
const noBlankSpaces = value =>
  value.trim() === '' ? 'Remove blank space' : undefined;

const emailRegex = value =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    String(value).toLowerCase()
  );

const passwordRegex = value =>
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&?*><|=+,{}./_-])(?=.{12,})/.test(
    value
  );

const panRegex = value => '[A-Z]{5}[0-9]{4}[A-Z]{1}'.test(value);

const onlyNumbers = value => /^[0-9]*$/.test(value);

const fullName = value => /^[a-zA-Z0-9_ ]*$/.test(value);
const folderName = value => /^[a-zA-Z0-9+&_ ]*$/.test(value);

const noSpecialCharacters = value => /^[a-zA-Z0-9]*$/.test(value);

const charactersLengthCheck = value => {
  if (!value) return required(value);
  if (value.length > 100) return CHARACTERS_LENGTH_EXCEED_MESSAGE;
  return undefined;
};

const email = value => {
  if (!value) {
    return required(value);
  }
  if (!emailRegex(value)) {
    return 'Enter valid email address';
  }
  if (value.length > 100) return CHARACTERS_LENGTH_EXCEED_MESSAGE;
  return undefined;
};

const noSpaces = e => e.which === 32 && e.preventDefault();

const password = value => {
  if (!value) return required(value);
  if (!passwordRegex(value))
    return 'Use 12 or more characters with a mix of letters(at least 1 uppercase), numbers & symbols(!@#$%^&?*-=_+|}{,><)';
  if (value.length > 100) return CHARACTERS_LENGTH_EXCEED_MESSAGE;
  return undefined;
};

const bucketFolderPathRegex = value => {
  if (!value) return undefined;
  if (value.includes(' ')) return 'Remove blank space';
  if (value === '/' || value?.includes('//')) {
    // eslint-disable-next-line quotes
    return 'Avoid using // at a time';
  }
  if (!/^[a-zA-Z0-9!*./_-]*$/.test(value)) {
    // eslint-disable-next-line quotes
    return `Use mix of letters, numbers & symbols(!-_.*')`;
  }
  return undefined;
};

const bucketRegex = value => {
  if (!value) return required(value);
  if (value.includes(' ')) return 'Remove blank space';
  if (!/^[a-zA-Z0-9.\-_]{1,255}$/.test(value)) {
    return 'Use mix of letters, numbers & symbols(-_)';
  }
  return undefined;
};

export {
  required,
  email,
  emailRegex,
  noSpaces,
  onlyNumbers,
  fullName,
  noBlankSpaces,
  password,
  bucketFolderPathRegex,
  bucketRegex,
  charactersLengthCheck,
  panRegex,
  noSpecialCharacters,
  folderName,
};
